import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { composeValidators } from 'utils';
import { required, webSiteLink, email, integerValidator } from 'utils/validation';
import History from 'services/History';
import {
  FieldSingleSelect,
  FieldTextInput,
  MaskedTextInput,
} from 'components/FormFields';
import { UIButton } from 'components/UI';
import { phoneNumberMaskedProps } from 'utils/formatters';

import { CompanyContact } from 'interfaces/companies';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { Field } from 'components/FinalForm';

export interface FormProps {
  onSubmit: (values: CompanyContact) => OnSubmitReturnType;
  initialValues?: CompanyContact | {};
  isLoading?: boolean;
}

const CompanyContactForm = ({ onSubmit, initialValues }: FormProps) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }: FormRenderProps<CompanyContact>) => {
        return (
          <form>
            <Body>
              <FieldSection marginBottom="8px">
                <TitleSection>Info</TitleSection>
                <Row>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={FieldTextInput}
                    placeholder="Enter first Name"
                    validate={required}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={FieldTextInput}
                    placeholder="Enter last Name"
                    validate={required}
                  />
                </Row>

                <Row>
                  <Field
                    name="middleName"
                    label="Middle Name"
                    component={FieldTextInput}
                    placeholder="Enter middle Name"
                  />
                  <Field
                    name="jobTitle"
                    label="Job Title"
                    component={FieldTextInput}
                    placeholder="Enter job Title"
                    validate={required}
                  />
                </Row>

                <Row>
                  <Field
                    name="jobFunction"
                    label="Job Function"
                    component={FieldSingleSelect}
                    placeholder="Select Job Function"
                    options={[
                      {
                        value: 'Community and Social Services',
                        label: 'Community and Social Services',
                      },
                      { value: 'Human Resources', label: 'Human Resources' },
                      { value: 'Marketing', label: 'Marketing' },
                      {
                        value: 'Media and Communications and PR',
                        label: 'Media and Communications and PR',
                      },
                      { value: 'CSR', label: 'CSR' },
                      {
                        value: 'Diversity and Inclusion',
                        label: 'Diversity and Inclusion',
                      },
                      { value: 'Social Impact', label: 'Social Impact' },
                    ]}
                  />
                  <Field
                    name="managementLevel"
                    label="Management Level"
                    component={FieldSingleSelect}
                    placeholder="Select Management Level"
                    options={[
                      { value: 'M Level', label: 'M Level' },
                      { value: 'C Level', label: 'C Level' },
                      { value: 'D Level', label: 'D Level' },
                      { value: 'VP Level', label: 'VP Level' },
                    ]}
                  />
                </Row>

                <Row>
                  <Field
                    name="phone"
                    label="Phone"
                    mask={phoneNumberMaskedProps.mask}
                    component={MaskedTextInput}
                    placeholder="Enter phone"
                  />

                  <Field
                    name="email"
                    label="Email"
                    component={FieldTextInput}
                    placeholder="Enter email"
                    validate={composeValidators<string>(required, email)}
                  />
                </Row>
              </FieldSection>

              <Row>
                <Field
                  name="linkedinUrl"
                  label="LinkedIn"
                  component={FieldTextInput}
                  placeholder="Enter LinkedIn url"
                  allowNull={true}
                  validate={webSiteLink}
                />
                <Field
                  name="contactAccuracyScore"
                  label="Accuracy Score"
                  component={FieldTextInput}
                  placeholder="Enter Accuracy Score"
                  validate={integerValidator}
                />
              </Row>

              <BtnGroup>
                <UIButton
                  handleClick={() => History.back()}
                  modifiers="secondary"
                  title="Cancel"
                />
                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </form>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '32px'};
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
  margin-bottom: 40px;
`;

export default CompanyContactForm;
