import React from 'react';
import { Location } from 'history';
import { Link, NavLink } from 'react-router-dom';
import HeaderDropDown from 'components/HeaderDropDown';
import styled from 'styled-components';
import { useLogout } from 'hooks/common';
import LogoImg from 'assets/images/Logo.svg';

export interface HeaderProps {
  location: Location;
}

const Header = ({ location }: HeaderProps) => {
  const { logout } = useLogout();

  return (
    <HeaderWrapper>
      <HeaderInner>
        <Left>
          <Link to="/">
            <Logo src={LogoImg} alt="Accelerist" />
          </Link>
          <LinkList>
            <LinkElem to="/teams">Organizations</LinkElem>
            <LinkElem to="/users">Users</LinkElem>
            <LinkElem to="/companies">Companies</LinkElem>
            <LinkElem to="/monthly-report">Monthly Report</LinkElem>
          </LinkList>
        </Left>
        <Right>
          <HeaderDropDown location={location} handleLogout={logout} />
        </Right>
      </HeaderInner>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #d5f3ff;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  min-width: 730px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const HeaderInner = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
  margin-right: 44px;
`;

const LinkList = styled.div`
  display: flex;
`;

const LinkElem = styled(NavLink)`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  border-bottom: 2px solid transparent;
  margin-top: 3px;
  padding: 0 3px 3px 3px;

  &:not(:last-child) {
    margin-right: 22px;
  }

  &:hover {
    border-color: var(--black);
  }

  &.active {
    border-color: var(--black);
  }
`;

export default Header;
