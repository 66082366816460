export const headTableTeams = [
  'Name',
  'Website',
  'Owner email',
  'Activity',
  'Contact Phone',
  'Address',
];

export const headTableUsers = [
  'Name',
  'Email',
  'Role',
  'Last Login',
  'Created At',
  'Updated',
  'Status',
];

export const headTableCompanies = [
  'Name',
  'Phone',
  'Website',
  'Country',
  'State',
  'City',
  'Street',
  'SI',
];
